import Utilisateur from '@/models/utilisateurs/Utilisateur'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

/**
* @Name UtilisateursApiClientUrlsInterface
* @description
* Interface for the Utilisateurs urls used to avoid hard-coded strings
*/
export interface UtilisateursApiClientUrlsInterface {
    getUtilisateurs: string
    getUtilisateur: string
    postUtilisateur: string
    putUtilisateur: string
    deleteUtilisateur: string
}

/**
* @Name UtilisateursApiClientInterface
* @description
* Interface for the Utilisateurs api client module
*/
export interface UtilisateursApiClientInterface {
    getUtilisateurs: (apiFilters?: any) => Promise<Utilisateur[]>
    getUtilisateur: (utilisateur: Utilisateur) => Promise<Utilisateur>
    sendUtilisateur: (utilisateur: Utilisateur) => Promise<Utilisateur>
    deleteUtilisateur: (utilisateur: Utilisateur) => Promise<Utilisateur>
}

/**
* @Name UtilisateursApiClientModel
* @description
* Implements the UtilisateursApiClientInterface interface
*/
export class UtilisateursApiClientModel implements UtilisateursApiClientInterface {
    private readonly urls!: UtilisateursApiClientUrlsInterface
    constructor(urls: UtilisateursApiClientUrlsInterface) {
        this.urls = urls
    }

    getUtilisateurs(apiFilters?: any): Promise<Utilisateur[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getUtilisateurs,
            requiresToken: false,
            filters: apiFilters
        }
        return HttpClient.get<Utilisateur[]>(getParameters)
    }

    getUtilisateur(utilisateur: Utilisateur): Promise<Utilisateur> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getUtilisateur.replace('$(utilisateurId)', utilisateur.id),
            requiresToken: false,
        }
        return HttpClient.get<Utilisateur>(getParameters)
    }

    sendUtilisateur(utilisateur: Utilisateur): Promise<Utilisateur> {
        if (utilisateur.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putUtilisateur.replace('$(utilisateurId)', utilisateur.id),
                requiresToken: false,
                payload: utilisateur,
            }
            return HttpClient.put<Utilisateur>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postUtilisateur.replace('$(utilisateurId)', utilisateur.id),
                requiresToken: false,
                payload: utilisateur,
            }
            return HttpClient.post<Utilisateur>(getParameters)
        }
    }

    deleteUtilisateur(utilisateur: Utilisateur): Promise<Utilisateur> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteUtilisateur.replace('$(utilisateurId)', utilisateur.id),
            requiresToken: false,
        }
        return HttpClient.delete<Utilisateur>(getParameters)
    }
}
