
export default class Artisan {
  id: string;
  nom?: string
  bien?: string

  constructor() {
    this.id = 'notSet'
    this.nom = undefined;
    this.bien = undefined;
  }
}
