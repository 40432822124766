
export default class Projet {
  id: string;
  visite: any;
  is_reno_global: boolean;
  type_travaux: string[] | null;
  // couverture: string[] | null;
  facade: string[] | null;
  menuiserie: string[] | null;
  // maconnerie: string[] | null;
  depose?: string;
  // chauffage: string[] | null;
  pac?: string
  is_mise_service_entreprise?: boolean
  mise_service_entreprise?: string
  is_entretien_entreprise?: boolean
  entretien_entreprise?: string
  ventilation: string[] | null;
  // sdb: string[] | null;
  plancher: string[] | null;
  photovoltaique: string[] | null;
  is_eligible_aide: boolean;
  is_aide_used: boolean;
  travaux_finances?: string;
  is_carnet_open: boolean;
  montant_prev?: number;
  nature_financement?: string;
  commentaire?: string;
  montant_aides_access?: number;
  mpr?: number;
  autres?: number;
  cout_audit?: number;
  rac_client?: number;
  date_realisation?: Date;
  etat?: string;
  chaudiere?: string;
  combles?: string[];
  iti?: string[]
  eau_chaude?: string;
  is_dep_eau_chaude?: boolean;
  is_rempl_convecteur?: boolean;
  poele?: string;
  climatisation?: string;
  is_artisans?: boolean;
  artisans?: Array<any>;


  constructor() {
    this.id = 'notSet'
    this.visite = undefined;
    this.is_reno_global = false;
    this.type_travaux = [];
    // this.couverture = [];
    this.facade = [];
    this.menuiserie = [];
    // this.maconnerie = [];
    this.depose = undefined;
    // this.chauffage = [];
    this.pac = undefined;
    this.ventilation = [];
    // this.sdb = [];
    this.plancher = [];
    this.photovoltaique = [];
    this.is_eligible_aide = false;
    this.is_aide_used = false;
    this.travaux_finances = undefined;
    this.is_carnet_open = false;
    this.montant_prev = undefined;
    this.nature_financement = undefined;
    this.commentaire = undefined;
    this.montant_aides_access = undefined;
    this.mpr = undefined;
    this.autres = undefined;
    this.cout_audit = undefined;
    this.rac_client = undefined;
    this.date_realisation = undefined;
    this.etat = "etat?";
    this.iti = [];
    this.combles = [];
    this.climatisation = undefined;
    this.is_artisans = false;
    this.artisans = []
  }
}
