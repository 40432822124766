export default class Environnement {
    id: string
    visite?: any; 
    departement?: string;
    altitude?: number;
    surface_plancher?: number;
    surface_habitable?: number;
    surface_chauffee?: number;
    zone_climatique?: string;
    zone_parasismique?: string;
    is_zone_inondable: boolean;
    is_zone_radon: boolean;
    concentration_radon?: number;
    is_zone_de_bruit: boolean;
    zone_de_bruit?: string;
    risque_insectes?: string;
    alea_argile_gonflante?: string;
    is_zone_classee: boolean;
    is_acces_chantier_pl: boolean;
    is_stationnement_prev: boolean;
    is_emprise_sur_voie_publique: boolean;
    code_acces_chantier?: string;
    is_reseaux_eau_electricite: boolean;
    type_reseau?: string;
    is_echelle: boolean;
    is_harnais: boolean;
    is_nacelle: boolean;
    is_echafaudage: boolean;
    is_echafaudage_spe: boolean;
    autre_materiel?: string;
    materiel_a_prevoir: string[];
  
    constructor() {
      this.id = 'notSet';
      this.visite = undefined;
      this.departement = undefined;
      this.surface_plancher = undefined;
      this.surface_habitable = undefined;
      this.zone_climatique = undefined;
      this.zone_parasismique = undefined;
      this.is_zone_inondable = false;
      this.is_zone_radon = false;
      this.is_zone_de_bruit = false;
      this.zone_de_bruit = undefined;
      this.risque_insectes = undefined;
      this.alea_argile_gonflante = undefined;
      this.is_zone_classee = false;
      this.is_acces_chantier_pl = false;
      this.is_stationnement_prev = false;
      this.is_emprise_sur_voie_publique = false;
      this.is_reseaux_eau_electricite = false;
      this.type_reseau = undefined;
      this.is_echelle = false;
      this.is_harnais = false;
      this.is_nacelle = false;
      this.is_echafaudage = false;
      this.is_echafaudage_spe = false;
      this.materiel_a_prevoir = []
    }
}