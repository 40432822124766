export default class Fichier {
  id?: string;
  fichier?: string;

  constructor() {
    this.id = undefined;
    this.fichier = undefined;
  }

  get name(): string {
      return this.fichier!.split('/')[this.fichier!.split('/').length - 1]
  }

}