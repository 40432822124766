export default class SystemeChauffage {
  id: string;
  bien?: string; 
  presence_insert?: string;
  images_insert: Array<any>;
  etat_conduit?: string;
  systeme_chauffage?: string;
  emplacement?: string;
  is_emplacement_chauffe?: boolean;
  images_systeme_chauffage: Array<any>;
  reference_systeme?: string;
  images_reference_systeme: Array<any>;
  type_pac?: string;
  type_gaz?: string;
  type_fioul?: string;
  type_bois?: string;
  type_charbon?: string;
  is_presence_programmateur?: boolean;
  images_programmateur: Array<any>;
  etat_chauffage?: string;

  constructor() {
    this.id = 'notSet';
    this.bien = undefined;
    this.presence_insert = undefined;
    this.images_insert = [];
    this.etat_conduit = undefined;
    this.systeme_chauffage = undefined;
    this.emplacement = undefined;
    this.is_emplacement_chauffe = undefined;
    this.images_systeme_chauffage = [];
    this.reference_systeme = undefined;
    this.images_reference_systeme = [];
    this.type_pac = undefined;
    this.type_gaz = undefined;
    this.type_fioul = undefined;
    this.type_bois = undefined;
    this.type_charbon = undefined;
    this.is_presence_programmateur = undefined;
    this.images_programmateur = [];
    this.etat_chauffage = undefined;
  }
}