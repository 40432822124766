import {
    BiensApiClientUrlsInterface,
    BiensApiClientInterface,
    BiensApiClientModel
} from '@/models/api-client/biens/BiensApiClient'

const urls: BiensApiClientUrlsInterface = {
    getBiens: process.env.VUE_APP_API_URL + '/api/biens',
    getBien: process.env.VUE_APP_API_URL + '/api/biens/$(bienId)',
    postBien: process.env.VUE_APP_API_URL + '/api/biens',
    putBien: process.env.VUE_APP_API_URL + '/api/biens/$(bienId)',
    deleteBien: process.env.VUE_APP_API_URL + '/api/biens/$(bienId)',

    getFaces: process.env.VUE_APP_API_URL + '/api/biens/faces',
    getFace: process.env.VUE_APP_API_URL + '/api/biens/faces/$(faceId)',
    postFace: process.env.VUE_APP_API_URL + '/api/biens/faces',
    putFace: process.env.VUE_APP_API_URL + '/api/biens/faces/$(faceId)',
    deleteFace: process.env.VUE_APP_API_URL + '/api/biens/faces/$(faceId)',

    getPieces: process.env.VUE_APP_API_URL + '/api/biens/pieces',
    getPiece: process.env.VUE_APP_API_URL + '/api/biens/pieces/$(pieceId)',
    postPiece: process.env.VUE_APP_API_URL + '/api/biens/pieces',
    putPiece: process.env.VUE_APP_API_URL + '/api/biens/pieces/$(pieceId)',
    deletePiece: process.env.VUE_APP_API_URL + '/api/biens/pieces/$(pieceId)',

    getPlanchers: process.env.VUE_APP_API_URL + '/api/biens/planchers',
    getPlancher: process.env.VUE_APP_API_URL + '/api/biens/planchers/$(plancherId)',
    postPlancher: process.env.VUE_APP_API_URL + '/api/biens/planchers',
    putPlancher: process.env.VUE_APP_API_URL + '/api/biens/planchers/$(plancherId)',
    deletePlancher: process.env.VUE_APP_API_URL + '/api/biens/planchers/$(plancherId)',

    getToitures: process.env.VUE_APP_API_URL + '/api/biens/toitures',
    getToiture: process.env.VUE_APP_API_URL + '/api/biens/toitures/$(toitureId)',
    postToiture: process.env.VUE_APP_API_URL + '/api/biens/toitures',
    putToiture: process.env.VUE_APP_API_URL + '/api/biens/toitures/$(toitureId)',
    deleteToiture: process.env.VUE_APP_API_URL + '/api/biens/toitures/$(toitureId)',

    getChauffages: process.env.VUE_APP_API_URL + '/api/biens/chauffages',
    getChauffage: process.env.VUE_APP_API_URL + '/api/biens/chauffages/$(chauffageId)',
    postChauffage: process.env.VUE_APP_API_URL + '/api/biens/chauffages',
    putChauffage: process.env.VUE_APP_API_URL + '/api/biens/chauffages/$(chauffageId)',
    deleteChauffage: process.env.VUE_APP_API_URL + '/api/biens/chauffages/$(chauffageId)',

    getECSs: process.env.VUE_APP_API_URL + '/api/biens/ecs',
    getECS: process.env.VUE_APP_API_URL + '/api/biens/ecs/$(ecsId)',
    postECS: process.env.VUE_APP_API_URL + '/api/biens/ecs',
    putECS: process.env.VUE_APP_API_URL + '/api/biens/ecs/$(ecsId)',
    deleteECS: process.env.VUE_APP_API_URL + '/api/biens/ecs/$(ecsId)',
}

// instantiate the BiensApiClient pointing at the url that returns static json mock \data
const biensApiClient: BiensApiClientInterface = new BiensApiClientModel(urls)

// export our instance
export default biensApiClient
