import User from '@/models/users/User'
import Action from '@/models/actions/Action'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import Permission from '@/models/users/Permission'

/**
* @Name UsersApiClientUrlsInterface
* @description
* Interface for the Users urls used to avoid hard-coded strings
*/
export interface UsersApiClientUrlsInterface {
    getToken: string
    resetPassword: string
    changePassword: string
    simulateUser: string
    getPermissions: string

    getPermissionObjects: string
}

/**
* @Name UsersApiClientInterface
* @description
* Interface for the Users api client module
*/
export interface UsersApiClientInterface {
    getToken: (username: string, password: string) => Promise<User>
    resetPassword: (email: string) => Promise<User>
    changePassword: (action: Action) => Promise<User>
    simulateUser: (userId: string) => Promise<User>
    getPermissions: (userId: string) => Promise<string[]>

    getPermissionObjects: () => Promise<Permission[]>
}

/**
* @Name UsersApiClientModel
* @description
* Implements the UsersApiClientInterface interface
*/
export class UsersApiClientModel implements UsersApiClientInterface {
    private readonly urls!: UsersApiClientUrlsInterface
    constructor(urls: UsersApiClientUrlsInterface) {
        this.urls = urls
    }

    getToken(username: string, password: string): Promise<User> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getToken,
            requiresToken: false,
            payload: {username: username, password: password}
        }
        return HttpClient.post<User>(getParameters)
    }

    resetPassword(email: string): Promise<User> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.resetPassword.replace('$(email)', email),
            requiresToken: false,
        }
        return HttpClient.post<User>(getParameters)
    }

    changePassword(action: Action): Promise<User> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.changePassword.replace('$(actionId)', action.id),
            requiresToken: false,
            payload: {action: action}
        }
        return HttpClient.put<User>(getParameters)
    }

    simulateUser(userId: string): Promise<User> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.simulateUser.replace('$(userId)', userId),
            requiresToken: false
        }
        return HttpClient.get<User>(getParameters)
    }

    getPermissions(userId: string): Promise<string[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getPermissions.replace('$(userId)', userId),
            requiresToken: false
        }
        return HttpClient.get<string[]>(getParameters)
    }

    getPermissionObjects(): Promise<Permission[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getPermissionObjects,
            requiresToken: false
        }
        return HttpClient.get<Permission[]>(getParameters)
    }
}
