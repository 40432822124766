import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { MutationType, RootStateInterface, UsersStateInterface } from '@/models/store'
import { initialUsersState } from './initialState'
import User from '@/models/users/User'
import apiClient from '@/api-client'
import router from "@/router";
import Collaborateur from '@/models/collaborateurs/Collaborateur'
import Permission from '@/models/users/Permission'

export const mutations: MutationTree<UsersStateInterface> = {
    loadingAppUser(state: UsersStateInterface, params: { username: string; password: string }) {
        state.loading = true
        state.appUser.email = params.username
        state.appUser.password = params.password

    },
    loadedAppUser(state: UsersStateInterface, data) {
        state.appUser = data;
        if(data.collaborateur) {
            apiClient.collaborateurs.getCollaborateur(state.appUser.collaborateur!).then((data: Collaborateur) => {
                state.appUser.collaborateur = Object.assign(new Collaborateur(), data);
                
                if(data){
                    localStorage.setItem('appUser', JSON.stringify(state.appUser))
                    state.loading = false
                    router.push({path: "/visites"})
                }else{
                    localStorage.setItem('appUser', JSON.stringify(state.appUser))
                    state.loading = false
                    router.push({path: "/visites"})
                }
            })
        }
       
    },
    loadedFromLocalstorageAppUser(state: UsersStateInterface, param) {
        state.appUser = param.appUser
    },
    logedoutAppUser(state: UsersStateInterface, param) {
        state.appUser = {} as User;
        localStorage.setItem('appUser', JSON.stringify(state.appUser));
    },
    simulatedUser(state: UsersStateInterface, data) {
        state.appUser = data
       
        apiClient.collaborateurs.getCollaborateur(state.appUser.collaborateur!).then((data: Collaborateur) => {
            state.appUser.collaborateur = Object.assign(new Collaborateur(), data);
            localStorage.setItem('appUser', JSON.stringify(state.appUser))
            state.loading = false
            router.push({path: "/visites"})
        
        })
    }
}

export const actions: ActionTree<UsersStateInterface, RootStateInterface> = {
    loadAppUser({ commit }, params: { username: string; password: string }) {
        commit(MutationType.users.loadingAppUser, params)

        apiClient.users.getToken(params.username, params.password).then((data: User) => {
            commit(MutationType.users.loadedAppUser, data)
        })
    },
    loadFromLocalstorageAppUser({ commit }, params: { appUser: User }) {
        commit(MutationType.users.loadedFromLocalstorageAppUser, params)
    },
    logoutAppUser({ commit }, params: {}) {
        commit(MutationType.users.logedoutAppUser, params)
    },
    simulateUser({ commit }, params: { userId: string }) {
        commit(MutationType.users.loadingAppUser, params)

        apiClient.users.simulateUser(params.userId).then((data: User) => {
            commit(MutationType.users.simulatedUser, data)
        })
    }
}

const namespaced = true
const state: UsersStateInterface = initialUsersState
export const usersState: Module<UsersStateInterface, RootStateInterface> = {
    namespaced,
    state,
    actions,
    mutations
}