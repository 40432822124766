<template>
  <Toast position="top-right" />
  <slot />
</template>

<script lang="ts">
</script>

<style scoped>

</style>