export default class Toiture {
  id: string;
  bien?: string;
  type_toiture?: string;
  images_toiture: Array<any>;
  etat_toiture?: string;
  if_toiture_terrasse?: string;
  images_toiture_terrasse: Array<any>;
  presence_conduit_fumee?: string;
  images_conduit_fumee: Array<any>;
  type_charpente?: string;
  etat_charpente?: string;
  images_charpente: Array<any>;
  is_presence_bache?: boolean;
  type_combles?: string;
  isolation_comble?: string;
  images_isolation_comble: Array<any>;
  etat_isolation_comble?: string;
  is_presence_ventilation?: boolean;
  images_ventilation: Array<any>;
  is_depollution?: boolean;
  presence_fenetre_toit?: string;
  images_fenetres_toit: Array<any>;
  is_presence_trappe_comble?: boolean;
  dimension_trappe?: string;
  lieu_trappe?: string;
  images_trappe_comble: Array<any>;
  is_isolation_trappe?: boolean;
  commentaire_comble?: string
  structure_plafond?: string;
  images_plafond: Array<any>;
  presence_spots?: string;
  images_spots: Array<any>;
  etat_plafond?: string;

  constructor() {
    this.id = 'notSet';
    this.bien = undefined;
    this.type_toiture = undefined;
    this.images_toiture = [];
    this.etat_toiture = undefined;
    this.if_toiture_terrasse = undefined;
    this.images_toiture_terrasse = [];
    this.presence_conduit_fumee = undefined;
    this.images_conduit_fumee = [];
    this.type_charpente = undefined;
    this.etat_charpente = undefined;
    this.images_charpente = [];
    this.is_presence_bache = undefined,
    this.type_combles = undefined;
    this.isolation_comble = undefined;
    this.images_isolation_comble = [];
    this.etat_isolation_comble = undefined;
    this.is_presence_ventilation = undefined;
    this.images_ventilation = [];
    this.is_depollution = undefined;
    this.presence_fenetre_toit = undefined;
    this.images_fenetres_toit = [];
    this.is_presence_trappe_comble = undefined;
    this.dimension_trappe = undefined;
    this.lieu_trappe = undefined;
    this.images_trappe_comble = [];
    this.is_isolation_trappe = undefined;
    this.commentaire_comble = undefined;
    this.structure_plafond = undefined;
    this.images_plafond = [];
    this.presence_spots = undefined;
    this.images_spots = [];
    this.etat_plafond = undefined;
  }
}