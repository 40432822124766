import Franchise from '@/models/collaborateurs/Franchise'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

/**
* @Name FranchisesApiClientUrlsInterface
* @description
* Interface for the Franchises urls used to avoid hard-coded strings
*/
export interface FranchisesApiClientUrlsInterface {
    getFranchises: string
    getFranchise: string
    putFranchise: string
    postFranchise: string
    deleteFranchise: string
}

/**
* @Name FranchisesApiClientInterface
* @description
* Interface for the Franchises api client module
*/
export interface FranchisesApiClientInterface {
    getFranchises: (apiFilters?: any) => Promise<Franchise[]>
    getFranchise: (franchise: Franchise) => Promise<Franchise>
    sendFranchise: (franchise: Franchise) => Promise<Franchise>
    deleteFranchise: (franchise: Franchise) => Promise<Franchise>
}

/**
* @Name FranchisesApiClientModel
* @description
* Implements the FranchisesApiClientInterface interface
*/
export class FranchisesApiClientModel implements FranchisesApiClientInterface {
    private readonly urls!: FranchisesApiClientUrlsInterface
    constructor(urls: FranchisesApiClientUrlsInterface) {
        this.urls = urls
    }

    getFranchises(apiFilters?: any): Promise<Franchise[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getFranchises,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Franchise[]>(getParameters)
    }

    getFranchise(franchise: Franchise): Promise<Franchise> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getFranchise.replace('$(franchiseId)', franchise.id!),
            requiresToken: false,
        }
        return HttpClient.get<Franchise>(getParameters)
    }

    sendFranchise(_franchise: Franchise): Promise<Franchise> {
        const franchise = Object.assign({}, _franchise);
        if (franchise.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putFranchise.replace('$(franchiseId)', franchise.id!),
                requiresToken: false,
                payload: franchise,
            }
            return HttpClient.put<Franchise>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postFranchise.replace('$(franchiseId)', franchise.id!),
                requiresToken: false,
                payload: franchise,
            }
            return HttpClient.post<Franchise>(getParameters)
        }
    }

    deleteFranchise(franchise: Franchise): Promise<Franchise> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteFranchise.replace('$(franchiseId)', franchise.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Franchise>(getParameters)
    }
}
