import {
    FranchisesApiClientUrlsInterface,
    FranchisesApiClientInterface,
    FranchisesApiClientModel
} from '@/models/api-client/franchises/FranchisesApiClient'

const urls: FranchisesApiClientUrlsInterface = {
    getFranchises: process.env.VUE_APP_API_URL + '/api/franchises',
    getFranchise: process.env.VUE_APP_API_URL + '/api/franchises/$(franchiseId)',
    postFranchise: process.env.VUE_APP_API_URL + '/api/franchises',
    putFranchise: process.env.VUE_APP_API_URL + '/api/franchises/$(franchiseId)',
    deleteFranchise: process.env.VUE_APP_API_URL + '/api/franchises/$(franchiseId)'
}

// instantiate the FranchisesApiClient pointing at the url that returns static json mock \data
const franchisesApiClient: FranchisesApiClientInterface = new FranchisesApiClientModel(urls)

// export our instance
export default franchisesApiClient
