import { ApiClientInterface } from '@/models/api-client/ApiClient.interface'
import usersApiClient from './users'
import collaborateursApiClient from './collaborateurs'
import utilisateursApiClient from './utilisateurs'
import auditsApiClient from './audits'
import fichiersApiClient from './fichiers'
import visitesApiClient from './visites'
import imagesApiClient from './images'
import projetsApiClient from './projets'
import biensApiClient from './biens'
import franchisesApiClient from './franchises'


// create an instance of our main ApiClient that wraps the live child clients
const apiLiveClient: ApiClientInterface = {
    users: usersApiClient,
    collaborateurs: collaborateursApiClient,
    utilisateurs: utilisateursApiClient,
    images: imagesApiClient,
    audits: auditsApiClient,
    fichiers: fichiersApiClient,
    visites: visitesApiClient,
    projets: projetsApiClient,
    biens: biensApiClient,
    franchises: franchisesApiClient,
}
// export our instance
export default apiLiveClient