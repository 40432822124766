import { UsersStateInterface } from '@/models/store'
/**
* @name initialUsersState
* @description
* The Users state instance with the initial default values
*/
export const initialUsersState: UsersStateInterface = {
    loading: false,
    appUser: {
        id: "",
        prenom: "",
        nom: "",
        email: "",
        password: "",
        token: "",
        permissions: [],
        environement: '',
        database: '',
        real_user: undefined,
        is_mode_debug: false,
        is_super_user: false,
        app_client: undefined
    },
}