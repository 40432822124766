import Collaborateur from '@/models/collaborateurs/Collaborateur'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import Invitation from '@/models/users/Invitation'

/**
* @Name CollaborateursApiClientUrlsInterface
* @description
* Interface for the Collaborateurs urls used to avoid hard-coded strings
*/
export interface CollaborateursApiClientUrlsInterface {
    getCollaborateurs: string
    getCollaborateur: string
    postCollaborateur: string
    putCollaborateur: string
    deleteCollaborateur: string
    inviterUtilisateur: string
}

/**
* @Name CollaborateursApiClientInterface
* @description
* Interface for the Collaborateurs api client module
*/
export interface CollaborateursApiClientInterface {
    getCollaborateurs: (apiFilters?: any) => Promise<Collaborateur[]>
    getCollaborateur: (collaborateur: Collaborateur, apiFilters?: any) => Promise<Collaborateur>
    sendCollaborateur: (collaborateur: Collaborateur) => Promise<Collaborateur>
    deleteCollaborateur: (collaborateur: Collaborateur) => Promise<Collaborateur>

    inviterUtilisateur: (invitation: Invitation) => Promise<Collaborateur>
}

/**
* @Name CollaborateursApiClientModel
* @description
* Implements the CollaborateursApiClientInterface interface
*/
export class CollaborateursApiClientModel implements CollaborateursApiClientInterface {
    private readonly urls!: CollaborateursApiClientUrlsInterface
    constructor(urls: CollaborateursApiClientUrlsInterface) {
        this.urls = urls
    }

    getCollaborateurs(apiFilters?: any): Promise<Collaborateur[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getCollaborateurs,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Collaborateur[]>(getParameters)
    }

    getCollaborateur(collaborateur: Collaborateur,apiFilters?: any): Promise<Collaborateur> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getCollaborateur.replace('$(collaborateurId)', collaborateur.id!),
            requiresToken: false,
            filters: apiFilters
        }
        return HttpClient.get<Collaborateur>(getParameters)
    }

    sendCollaborateur(collaborateur: Collaborateur): Promise<Collaborateur> {
        if (collaborateur.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putCollaborateur.replace('$(collaborateurId)', collaborateur.id!),
                requiresToken: false,
                payload: collaborateur,
            }
            return HttpClient.put<Collaborateur>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postCollaborateur.replace('$(collaborateurId)', collaborateur.id!),
                requiresToken: false,
                payload: collaborateur,
            }
            return HttpClient.post<Collaborateur>(getParameters)
        }
    }

    deleteCollaborateur(collaborateur: Collaborateur): Promise<Collaborateur> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteCollaborateur.replace('$(collaborateurId)', collaborateur.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Collaborateur>(getParameters)
    }

    inviterUtilisateur(invitation: Invitation): Promise<Collaborateur> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.inviterUtilisateur,
            requiresToken: false,
            payload: invitation,
        }
        return HttpClient.post<Collaborateur>(getParameters)
    }

}
