// store/loading/module.ts
import {  StoreModuleNames, LoadingStateInterface } from '@/models/store';
import { rootStore, dispatchModuleAction } from '../root';

/**
 * @name LoadingStore
 * @description
 * Le store loading qui gère l'état de chargement global et le pourcentage de progression.
 */
const loadingStore = {
  get state(): LoadingStateInterface {
    return rootStore.state.loadingState;
  },
  action<T>(actionName: string, params?: T): void {
    dispatchModuleAction(StoreModuleNames.loadingState, actionName, params);
  },
};

// Exporter en utilisant la convention Composition API (i.e. useXYZ)
export const useLoadingStore = () => {
  return loadingStore;
};
