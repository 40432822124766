export default class ECS {
  id: string;
  bien?: string;
  eau_chaude?: string;
  images_eau_chaude: Array<any>;
  emplacement?: string;
  is_emplacement_chauffe?: boolean;
  type_electricite?: string;
  type_ce_electrique?: string;
  type_eau_gaz?: string;
  type_chaudiere?: string;
  type_eau_fioul?: string;
  type_eau_bois?: string;
  reference_eau?: string;
  presence_veilleuse?: boolean;
  is_presence_vanne_arret?: boolean;
  images_vanne_arret: Array<any>;
  is_presence_point_solaire?: boolean;
  type_appoint_solaire?: string;
  is_presence_calorifugeage?: boolean;
  classe_isolation?: string;
  etat_eau_chaude?: string;

  constructor() {
    this.id = 'notSet';
    this.bien = undefined;
    this.eau_chaude = undefined;
    this.images_eau_chaude = [];
    this.emplacement = undefined;
    this.is_emplacement_chauffe = undefined;
    this.type_electricite = undefined;
    this.type_ce_electrique = undefined;
    this.type_eau_gaz = undefined;
    this.type_chaudiere = undefined;
    this.type_eau_fioul = undefined;
    this.type_eau_bois = undefined;
    this.reference_eau = undefined;
    this.presence_veilleuse = undefined;
    this.is_presence_vanne_arret = undefined;
    this.images_vanne_arret = [];
    this.is_presence_point_solaire = undefined;
    this.type_appoint_solaire = undefined;
    this.is_presence_calorifugeage = undefined;
    this.classe_isolation = undefined;
    this.etat_eau_chaude = undefined;
  }
}
