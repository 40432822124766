// group our constants in a namespace
import { UsersMutationType } from '../users/UsersMutationType'
import { LocalesMutationType} from '../locales/LocalesMutationType'
import { ErrorMutationType} from '../error/ErrorMutationType'
import { LoadingMutationType } from '../loading/LoadingMutationType'

export namespace MutationType {
    export const users = UsersMutationType
    export const locales = LocalesMutationType
    export const error = ErrorMutationType
    export const loading = LoadingMutationType
    // as you add new state modules, add additional exports here following the same convention
}