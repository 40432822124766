
export default class Face {
  id: string;
  bien?: string;
  description: string;
  is_pignon?: boolean;
  largeur?: number;
  hauteur?: number;
  hauteur_faitage?: number;
  longueur?: number;
  niveau_dalle?: number;
  surface_a_isole?: number;
  images: Array<any>
  nb_evac: number
  images_evac_eau_pluviale: Array<any>
  etat_evac?: string
  index?: number

  constructor() {
    this.id = 'notSet'
    this.bien = undefined;
    this.description = '';
    this.is_pignon = undefined;
    this.largeur = undefined;
    this.hauteur = undefined;
    this.hauteur_faitage = undefined;
    this.longueur = undefined;
    this.niveau_dalle = undefined;
    this.surface_a_isole = undefined;
    this.images = [];
    this.nb_evac = 0;
    this.images_evac_eau_pluviale= [];
    this.etat_evac = undefined;
  }
}
