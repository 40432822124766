import {
    ProjetsApiClientUrlsInterface,
    ProjetsApiClientInterface,
    ProjetsApiClientModel
} from '@/models/api-client/projets/ProjetsApiClient'

const urls: ProjetsApiClientUrlsInterface = {
    getProjets: process.env.VUE_APP_API_URL + '/api/projets',
    getProjet: process.env.VUE_APP_API_URL + '/api/projets/$(projetId)',
    postProjet: process.env.VUE_APP_API_URL + '/api/projets',
    putProjet: process.env.VUE_APP_API_URL + '/api/projets/$(projetId)',
    deleteProjet: process.env.VUE_APP_API_URL + '/api/projets/$(projetId)'
}

// instantiate the ProjetsApiClient pointing at the url that returns static json mock \data
const projetsApiClient: ProjetsApiClientInterface = new ProjetsApiClientModel(urls)

// export our instance
export default projetsApiClient
