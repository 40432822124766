import Projet from '@/models/projets/Projet'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

/**
* @Name ProjetsApiClientUrlsInterface
* @description
* Interface for the Projets urls used to avoid hard-coded strings
*/
export interface ProjetsApiClientUrlsInterface {
    getProjets: string
    getProjet: string
    putProjet: string
    postProjet: string
    deleteProjet: string
}

/**
* @Name ProjetsApiClientInterface
* @description
* Interface for the Projets api client module
*/
export interface ProjetsApiClientInterface {
    getProjets: (apiFilters?: any) => Promise<Projet[]>
    getProjet: (projet: Projet) => Promise<Projet>
    sendProjet: (visite: Projet) => Promise<Projet>
    deleteProjet: (projet: Projet) => Promise<Projet>
}

/**
* @Name ProjetsApiClientModel
* @description
* Implements the ProjetsApiClientInterface interface
*/
export class ProjetsApiClientModel implements ProjetsApiClientInterface {
    private readonly urls!: ProjetsApiClientUrlsInterface
    constructor(urls: ProjetsApiClientUrlsInterface) {
        this.urls = urls
    }

    getProjets(apiFilters?: any): Promise<Projet[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getProjets,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Projet[]>(getParameters)
    }

    getProjet(projet: Projet): Promise<Projet> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getProjet.replace('$(projetId)', projet.id!),
            requiresToken: false,
        }
        return HttpClient.get<Projet>(getParameters)
    }

    sendProjet(_projet: Projet): Promise<Projet> {
        const projet = Object.assign({}, _projet);
        if (projet.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putProjet.replace('$(projetId)', projet.id!),
                requiresToken: false,
                payload: projet,
            }
            return HttpClient.put<Projet>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postProjet.replace('$(projetId)', projet.id!),
                requiresToken: false,
                payload: projet,
            }
            return HttpClient.post<Projet>(getParameters)
        }
    }

    deleteProjet(projet: Projet): Promise<Projet> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteProjet.replace('$(projetId)', projet.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Projet>(getParameters)
    }
}
