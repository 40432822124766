export default class Image {
  id?: string;
  image?: string;
  comment: string|null;

  constructor() {
    this.id = undefined;
    this.image = undefined;
    this.comment = null;
  }

}