import Fichier from '@/models/fichiers/Fichier'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

/**
* @Name FichiersApiClientUrlsInterface
* @description
* Interface for the Fichiers urls used to avoid hard-coded strings
*/
export interface FichiersApiClientUrlsInterface {
    getFichiers: string
    getFichier: string
    deleteFichier: string
}

/**
* @Name FichiersApiClientInterface
* @description
* Interface for the Fichiers api client module
*/
export interface FichiersApiClientInterface {
    getFichiers: (apiFilters?: any) => Promise<Fichier[]>
    getFichier: (fichier: Fichier, apiFilters?: any) => Promise<Fichier|any>
    deleteFichier: (fichier: Fichier) => Promise<Fichier>
}

/**
* @Name FichiersApiClientModel
* @description
* Implements the FichiersApiClientInterface interface
*/
export class FichiersApiClientModel implements FichiersApiClientInterface {
    private readonly urls!: FichiersApiClientUrlsInterface
    constructor(urls: FichiersApiClientUrlsInterface) {
        this.urls = urls
    }

    getFichiers(apiFilters?: any): Promise<Fichier[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getFichiers,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Fichier[]>(getParameters)
    }

    getFichier(fichier: Fichier, apiFilters?: any): Promise<Fichier> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getFichier.replace('$(fichierId)', fichier.id!),
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Fichier>(getParameters)
    }

    deleteFichier(fichier: Fichier): Promise<Fichier> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteFichier.replace('$(fichierId)', fichier.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Fichier>(getParameters)
    }
}
