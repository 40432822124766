import {
    ImagesApiClientUrlsInterface,
    ImagesApiClientInterface,
    ImagesApiClientModel
} from '@/models/api-client/images/ImagesApiClient'

const urls: ImagesApiClientUrlsInterface = {
    getImages: process.env.VUE_APP_API_URL + '/api/images',
    getImage: process.env.VUE_APP_API_URL + '/api/images/$(imageId)',
    updateImage: process.env.VUE_APP_API_URL + '/api/images/$(imageId)',
    deleteImage: process.env.VUE_APP_API_URL + '/api/images/$(imageId)'
}

// instantiate the ImagesApiClient pointing at the url that returns static json mock \data
const imagesApiClient: ImagesApiClientInterface = new ImagesApiClientModel(urls)

// export our instance
export default imagesApiClient