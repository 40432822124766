import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { MutationType, RootStateInterface, LoadingStateInterface } from '@/models/store'
import { initialLoadingState } from './initialState'

export const mutations: MutationTree<LoadingStateInterface> = {
    loadingProgress(state: LoadingStateInterface, params: number) {
        if(params == 100){
            state.loading = false
            state.progress = 0
        }else{
            state.loading = true
            state.progress = params
        }
        
    },
    setLoading(state: LoadingStateInterface) {
        state.loading = true
    },
}

export const actions: ActionTree<LoadingStateInterface, RootStateInterface> = {
    loadProgress({ commit }, params: number) {
        commit(MutationType.loading.loadingProgress, params)
    },
    setLoad({ commit }) {
        commit(MutationType.loading.setLoading)
    }
}

const namespaced = true
const state: LoadingStateInterface = initialLoadingState
export const loadingState: Module<LoadingStateInterface, RootStateInterface> = {
    namespaced,
    state,
    actions,
    mutations
}