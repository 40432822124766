import Bien from '@/models/biens/Bien'
import ECS from '@/models/biens/ECS'
import Face from '@/models/biens/Face'
import Piece from '@/models/biens/Piece'
import Plancher from '@/models/biens/Plancher'
import SystemeChauffage from '@/models/biens/SystemeChauffage'
import Toiture from '@/models/biens/Toiture'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

/**
* @Name BiensApiClientUrlsInterface
* @description
* Interface for the Biens urls used to avoid hard-coded strings
*/
export interface BiensApiClientUrlsInterface {
    getBiens: string
    getBien: string
    putBien: string
    postBien: string
    deleteBien: string

    getFaces: string
    getFace: string
    putFace: string
    postFace: string
    deleteFace: string

    getPieces: string
    getPiece: string
    putPiece: string
    postPiece: string
    deletePiece: string

    getPlanchers: string
    getPlancher: string
    putPlancher: string
    postPlancher: string
    deletePlancher: string

    getToitures: string
    getToiture: string
    putToiture: string
    postToiture: string
    deleteToiture: string

    getChauffages: string
    getChauffage: string
    putChauffage: string
    postChauffage: string
    deleteChauffage: string

    getECSs: string
    getECS: string
    putECS: string
    postECS: string
    deleteECS: string
}

/**
* @Name BiensApiClientInterface
* @description
* Interface for the Biens api client module
*/
export interface BiensApiClientInterface {
    getBiens: (apiFilters?: any) => Promise<Bien[]>
    getBien: (bien: Bien) => Promise<Bien>
    sendBien: (bien: Bien) => Promise<Bien>
    deleteBien: (bien: Bien) => Promise<Bien>

    getFaces: (apiFilters?: any) => Promise<Face[]>
    getFace: (face: Face) => Promise<Face>
    sendFace: (face: Face) => Promise<Face>
    deleteFace: (face: Face) => Promise<Face>

    getPieces: (apiFilters?: any) => Promise<Piece[]>
    getPiece: (face: Piece) => Promise<Piece>
    sendPiece: (face: Piece) => Promise<Piece>
    deletePiece: (face: Piece) => Promise<Piece>

    getPlanchers: (apiFilters?: any) => Promise<Plancher[]>
    getPlancher: (face: Plancher) => Promise<Plancher>
    sendPlancher: (face: Plancher) => Promise<Plancher>
    deletePlancher: (face: Plancher) => Promise<Plancher>

    getToitures: (apiFilters?: any) => Promise<Toiture[]>
    getToiture: (face: Toiture) => Promise<Toiture>
    sendToiture: (face: Toiture) => Promise<Toiture>
    deleteToiture: (face: Toiture) => Promise<Toiture>

    getChauffages: (apiFilters?: any) => Promise<SystemeChauffage[]>
    getChauffage: (face: SystemeChauffage) => Promise<SystemeChauffage>
    sendChauffage: (face: SystemeChauffage) => Promise<SystemeChauffage>
    deleteChauffage: (face: SystemeChauffage) => Promise<SystemeChauffage>

    getECSs: (apiFilters?: any) => Promise<ECS[]>
    getECS: (face: ECS) => Promise<ECS>
    sendECS: (face: ECS) => Promise<ECS>
    deleteECS: (face: ECS) => Promise<ECS>
}

/**
* @Name BiensApiClientModel
* @description
* Implements the BiensApiClientInterface interface
*/
export class BiensApiClientModel implements BiensApiClientInterface {
    private readonly urls!: BiensApiClientUrlsInterface
    constructor(urls: BiensApiClientUrlsInterface) {
        this.urls = urls
    }

    getBiens(apiFilters?: any): Promise<Bien[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getBiens,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Bien[]>(getParameters)
    }

    getBien(bien: Bien): Promise<Bien> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getBien.replace('$(bienId)', bien.id!),
            requiresToken: false,
        }
        return HttpClient.get<Bien>(getParameters)
    }

    sendBien(_bien: Bien): Promise<Bien> {
        const bien = Object.assign({}, _bien);
        if (bien.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putBien.replace('$(bienId)', bien.id!),
                requiresToken: false,
                payload: bien,
            }
            return HttpClient.put<Bien>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postBien.replace('$(bienId)', bien.id!),
                requiresToken: false,
                payload: bien,
            }
            return HttpClient.post<Bien>(getParameters)
        }
    }

    deleteBien(bien: Bien): Promise<Bien> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteBien.replace('$(bienId)', bien.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Bien>(getParameters)
    }

    getFaces(apiFilters?: any): Promise<Face[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getFaces,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Face[]>(getParameters)
    }

    getFace(face: Face): Promise<Face> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getFace.replace('$(faceId)', face.id!),
            requiresToken: false,
        }
        return HttpClient.get<Face>(getParameters)
    }

    sendFace(_face: Face): Promise<Face> {
        const face = Object.assign({}, _face);
        if (face.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putFace.replace('$(faceId)', face.id!),
                requiresToken: false,
                payload: face,
            }
            return HttpClient.put<Face>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postFace.replace('$(faceId)', face.id!),
                requiresToken: false,
                payload: face,
            }
            return HttpClient.post<Face>(getParameters)
        }
    }

    deleteFace(face: Face): Promise<Face> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteFace.replace('$(faceId)', face.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Face>(getParameters)
    }

    getPieces(apiFilters?: any): Promise<Piece[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getPieces,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Piece[]>(getParameters)
    }

    getPiece(piece: Piece): Promise<Piece> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getPiece.replace('$(pieceId)', piece.id!),
            requiresToken: false,
        }
        return HttpClient.get<Piece>(getParameters)
    }

    sendPiece(_piece: Piece): Promise<Piece> {
        const piece = Object.assign({}, _piece);
        if (piece.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putPiece.replace('$(pieceId)', piece.id!),
                requiresToken: false,
                payload: piece,
            }
            return HttpClient.put<Piece>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postPiece.replace('$(pieceId)', piece.id!),
                requiresToken: false,
                payload: piece,
            }
            return HttpClient.post<Piece>(getParameters)
        }
    }

    deletePiece(piece: Piece): Promise<Piece> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deletePiece.replace('$(pieceId)', piece.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Piece>(getParameters)
    }

    getPlanchers(apiFilters?: any): Promise<Plancher[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getPlanchers,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Plancher[]>(getParameters)
    }

    getPlancher(plancher: Plancher): Promise<Plancher> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getPlancher.replace('$(plancherId)', plancher.id!),
            requiresToken: false,
        }
        return HttpClient.get<Plancher>(getParameters)
    }

    sendPlancher(_plancher: Plancher): Promise<Plancher> {
        const plancher = Object.assign({}, _plancher);
        if (plancher.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putPlancher.replace('$(plancherId)', plancher.id!),
                requiresToken: false,
                payload: plancher,
            }
            return HttpClient.put<Plancher>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postPlancher.replace('$(plancherId)', plancher.id!),
                requiresToken: false,
                payload: plancher,
            }
            return HttpClient.post<Plancher>(getParameters)
        }
    }

    deletePlancher(plancher: Plancher): Promise<Plancher> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deletePlancher.replace('$(plancherId)', plancher.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Plancher>(getParameters)
    }
    getToitures(apiFilters?: any): Promise<Toiture[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getToitures,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Toiture[]>(getParameters)
    }

    getToiture(toiture: Toiture): Promise<Toiture> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getToiture.replace('$(toitureId)', toiture.id!),
            requiresToken: false,
        }
        return HttpClient.get<Toiture>(getParameters)
    }

    sendToiture(_toiture: Toiture): Promise<Toiture> {
        const toiture = Object.assign({}, _toiture);
        if (toiture.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putToiture.replace('$(toitureId)', toiture.id!),
                requiresToken: false,
                payload: toiture,
            }
            return HttpClient.put<Toiture>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postToiture.replace('$(toitureId)', toiture.id!),
                requiresToken: false,
                payload: toiture,
            }
            return HttpClient.post<Toiture>(getParameters)
        }
    }

    deleteToiture(toiture: Toiture): Promise<Toiture> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteToiture.replace('$(toitureId)', toiture.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Toiture>(getParameters)
    }

    getChauffages(apiFilters?: any): Promise<SystemeChauffage[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getChauffages,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<SystemeChauffage[]>(getParameters)
    }

    getChauffage(chauffage: SystemeChauffage): Promise<SystemeChauffage> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getChauffage.replace('$(chauffageId)', chauffage.id!),
            requiresToken: false,
        }
        return HttpClient.get<SystemeChauffage>(getParameters)
    }

    sendChauffage(_chauffage: SystemeChauffage): Promise<SystemeChauffage> {
        const chauffage = Object.assign({}, _chauffage);
        if (chauffage.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putChauffage.replace('$(chauffageId)', chauffage.id!),
                requiresToken: false,
                payload: chauffage,
            }
            return HttpClient.put<SystemeChauffage>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postChauffage.replace('$(chauffageId)', chauffage.id!),
                requiresToken: false,
                payload: chauffage,
            }
            return HttpClient.post<SystemeChauffage>(getParameters)
        }
    }

    deleteChauffage(chauffage: SystemeChauffage): Promise<SystemeChauffage> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteChauffage.replace('$(chauffageId)', chauffage.id!),
            requiresToken: false,
        }
        return HttpClient.delete<SystemeChauffage>(getParameters)
    }

    getECSs(apiFilters?: any): Promise<ECS[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getECSs,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<ECS[]>(getParameters)
    }

    getECS(ecs: ECS): Promise<ECS> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getECS.replace('$(ecsId)', ecs.id!),
            requiresToken: false,
        }
        return HttpClient.get<ECS>(getParameters)
    }

    sendECS(_ecs: ECS): Promise<ECS> {
        const ecs = Object.assign({}, _ecs);
        if (ecs.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putECS.replace('$(ecsId)', ecs.id!),
                requiresToken: false,
                payload: ecs,
            }
            return HttpClient.put<ECS>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postECS.replace('$(ecsId)', ecs.id!),
                requiresToken: false,
                payload: ecs,
            }
            return HttpClient.post<ECS>(getParameters)
        }
    }

    deleteECS(ecs: ECS): Promise<ECS> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteToiture.replace('$(ecsId)', ecs.id!),
            requiresToken: false,
        }
        return HttpClient.delete<ECS>(getParameters)
    }
}
