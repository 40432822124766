import {
    FichiersApiClientUrlsInterface,
    FichiersApiClientInterface,
    FichiersApiClientModel
} from '@/models/api-client/fichiers/FichiersApiClient'

const urls: FichiersApiClientUrlsInterface = {
    getFichiers: process.env.VUE_APP_API_URL + '/api/fichiers',
    getFichier: process.env.VUE_APP_API_URL + '/api/fichiers/$(fichierId)',
    deleteFichier: process.env.VUE_APP_API_URL + '/api/fichiers/$(fichierId)'
}

// instantiate the FichiersApiClient pointing at the url that returns static json mock \data
const fichiersApiClient: FichiersApiClientInterface = new FichiersApiClientModel(urls)

// export our instance
export default fichiersApiClient