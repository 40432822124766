export default class Plancher {
  id: string;
  bien?: string;
  type_plancher?: string;
  images_plancher: Array<any>;
  hauteur_sous_plafond?: number;
  is_isolated?: boolean;
  etat_isolant?: string;
  is_presence_humidite?: boolean;
  images_humidite: Array<any>
  is_presence_reseau?: boolean;
  images_reseau: Array<any>
  reseau_commentaire?: string;

  constructor() {
    this.id = 'notSet';
    this.bien = undefined;
    this.type_plancher = undefined;
    this.images_plancher = [];
    this.hauteur_sous_plafond = undefined;
    this.is_isolated = undefined;
    this.etat_isolant = undefined;
    this.is_presence_humidite = undefined;
    this.images_humidite = [];
    this.is_presence_reseau = undefined;
    this.images_reseau = [];
    this.reseau_commentaire = undefined;
  }
}