import Audit from '@/models/audits/Audit'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

/**
* @Name AuditsApiClientUrlsInterface
* @description
* Interface for the Audits urls used to avoid hard-coded strings
*/
export interface AuditsApiClientUrlsInterface {
    getAudits: string
    getAudit: string
}

/**
* @Name AuditsApiClientInterface
* @description
* Interface for the Audits api client module
*/
export interface AuditsApiClientInterface {
    getAudits: (filters: any) => Promise<Audit[]>
    getAudit: (audit: Audit) => Promise<Audit>
}

/**
* @Name AuditsApiClientModel
* @description
* Implements the AuditsApiClientInterface interface
*/
export class AuditsApiClientModel implements AuditsApiClientInterface {
    private readonly urls!: AuditsApiClientUrlsInterface
    constructor(urls: AuditsApiClientUrlsInterface) {
        this.urls = urls
    }

    getAudits(filters: any): Promise<Audit[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getAudits,
            requiresToken: false,
            filters: filters
        }
        return HttpClient.get<Audit[]>(getParameters)
    }
    getAudit(audit: Audit): Promise<Audit> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getAudit.replace('$(auditId)', audit.id),
            requiresToken: false
        }
        return HttpClient.get<Audit>(getParameters)
    }
}
