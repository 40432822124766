import {
    CollaborateursApiClientUrlsInterface,
    CollaborateursApiClientInterface,
    CollaborateursApiClientModel
} from '@/models/api-client/collaborateurs/CollaborateursApiClient'

const urls: CollaborateursApiClientUrlsInterface = {
    getCollaborateurs: process.env.VUE_APP_API_URL + '/api/collaborateurs',
    getCollaborateur: process.env.VUE_APP_API_URL + '/api/collaborateurs/$(collaborateurId)',
    postCollaborateur: process.env.VUE_APP_API_URL + '/api/collaborateurs',
    putCollaborateur: process.env.VUE_APP_API_URL + '/api/collaborateurs/$(collaborateurId)',
    deleteCollaborateur: process.env.VUE_APP_API_URL + '/api/collaborateurs/$(collaborateurId)',

    inviterUtilisateur: process.env.VUE_APP_API_URL + '/api/users/invitation',
}

// instantiate the CollaborateursApiClient pointing at the url that returns static json mock \data
const collaborateursApiClient: CollaborateursApiClientInterface = new CollaborateursApiClientModel(urls)

// export our instance
export default collaborateursApiClient
