export default class Chauffage {
  id: string | null;
  piece?: string;
  type: string;
  images_emetteur: any[];

  constructor() {
    this.id = null;
    this.piece = undefined;
    this.type = '';
    this.images_emetteur = []
  }
}