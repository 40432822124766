export default class Franchise {
    id?: string;
    nom?: string;
    collaborateurs?: Array<any>

    constructor() {
      this.id = undefined;
      this.nom = undefined;
      this.collaborateurs = [];
    }

}