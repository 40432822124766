export default class Menuiserie {
  id: string | null;
  piece?: string;
  type: string;
  matiere: string;
  nature_vitrage: string;
  type_isolation_vitrage?: string;
  occultation: string;
  hauteur: number;
  largeur: number;
  epaisseur: number;
  images_menuiserie: any[];

  constructor() {
    this.id = null;
    this.piece = undefined;
    this.type = '';
    this.matiere = '';
    this.nature_vitrage = '';
    this.type_isolation_vitrage = undefined;
    this.occultation = '';
    this.hauteur = 0;
    this.largeur = 0;
    this.epaisseur = 0;
    this.images_menuiserie = []
  }
}