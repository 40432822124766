import Image from '@/models/images/Image'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

/**
* @Name ImagesApiClientUrlsInterface
* @description
* Interface for the Images urls used to avoid hard-coded strings
*/
export interface ImagesApiClientUrlsInterface {
    getImages: string
    getImage: string
    deleteImage: string
    updateImage: string
}

/**
* @Name ImagesApiClientInterface
* @description
* Interface for the Images api client module
*/
export interface ImagesApiClientInterface {
    getImages: (apiFilters?: any) => Promise<Image[]>
    getImage: (image: Image) => Promise<Image>
    deleteImage: (image: Image) => Promise<Image>
    updateImage: (image: Image) => Promise<Image>
}

/**
* @Name ImagesApiClientModel
* @description
* Implements the ImagesApiClientInterface interface
*/
export class ImagesApiClientModel implements ImagesApiClientInterface {
    private readonly urls!: ImagesApiClientUrlsInterface
    constructor(urls: ImagesApiClientUrlsInterface) {
        this.urls = urls
    }

    getImages(apiFilters?: any): Promise<Image[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getImages,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Image[]>(getParameters)
    }

    getImage(image: Image): Promise<Image> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getImage.replace('$(imageId)', image.id!),
            requiresToken: false,
        }
        return HttpClient.get<Image>(getParameters)
    }

    updateImage(_image: Image): Promise<Image> {
        const image = Object.assign({}, _image);
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.updateImage.replace('$(imageId)', image.id!),
            requiresToken: false,
            payload: image,
        }
        return HttpClient.put<Image>(getParameters)
        
    }

    deleteImage(image: Image): Promise<Image> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteImage.replace('$(imageId)', image.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Image>(getParameters)
    }
}
