import { UsersApiClientUrlsInterface, UsersApiClientInterface, UsersApiClientModel } from '@/models/api-client/users/UsersApiClient.model'

const urls: UsersApiClientUrlsInterface = {
    getToken: process.env.VUE_APP_API_URL + '/api-token-auth',
    resetPassword: process.env.VUE_APP_API_URL + '/api/users/$(email)/resetpassword',
    changePassword: process.env.VUE_APP_API_URL + '/api/actions/$(actionId)/run',
    simulateUser: process.env.VUE_APP_API_URL + '/api/users/$(userId)/simulate',
    getPermissions: process.env.VUE_APP_API_URL + '/api/users/$(userId)/permissions',

    getPermissionObjects: process.env.VUE_APP_API_URL + '/api/permissions'
}

// instantiate the UsersApiClient pointing at the url that returns static json mock \data
const usersApiClient: UsersApiClientInterface = new UsersApiClientModel(urls)

// export our instance
export default usersApiClient
